import React from "react"
import { injectIntl } from "gatsby-plugin-intl"

import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Layout from "../components/layout/layout"
import SEO from "../components/header/seo"

const useStyles = makeStyles(theme => ({
  container: {
    padding: "50px 20px 50px",
    [theme.breakpoints.up("sm")]: {
      padding: "100px 50px 50px",
    },
  },
}))

const Impressum = props => {
  const classes = useStyles()
  const { intl } = props
  return (
    <Layout location={props.location}>
      <SEO
        title={intl.formatMessage({
          id: "impressum-footer",
        })}
      />
      <div className={classes.container}>
        <Typography variant="h1">
          {intl.formatMessage({
            id: "impressum-footer",
          })}
        </Typography>
      </div>
    </Layout>
  )
}

export default injectIntl(Impressum)
